export const BASE_URL = 'https://backend-app-athena-q-cus-1.azurewebsites.net';
// athena-qa.medvantx.com
// window?.env?.BASE_URL || '';
const ENCOUNTER_API = `${BASE_URL}/encounter`;
const RESOURCE_API = `${BASE_URL}/resource`;

export const ANNOUNCEMENT = `${BASE_URL}/Announcement`;

export const REPORTSCHEDULE = `${BASE_URL}/ReportSchedule`;

export const SFTPDetails = `${BASE_URL}/SFTPDetails`

export const Setting = {
  DEPARTMENT: `${BASE_URL}/Department`,
  CLIENT: `${BASE_URL}/Client`,
  REPORT: `${BASE_URL}/Report`,
  ItemMaster: `${BASE_URL}/ItemMaster`,
  PharmacyHolidays: `${BASE_URL}/PharmacyHolidays`,
  DEPARTMENTREPORT: `${BASE_URL}/DepartmentReport`,
  PROGRAM: `${BASE_URL}/Program`,
  REPORTDROPDOWN: `${BASE_URL}/GetReportDropdowns`,
  GETCLIENTIDNAME: `${BASE_URL}/GetClientIdName`,
  GETPROGRAMIDNAME: `${BASE_URL}/GetProgramIdName`,
  REPORTSCHEDULE: `${BASE_URL}/ReportSchedule`
} as const;

export const Users = {
  USERS: `${BASE_URL}/Users`,
  LoginDetails: `${BASE_URL}/LoginDetails`,
  USERDETAILS: `${BASE_URL}/UserDetails`,
} as const;

export const Reports = { POWERBI: `${BASE_URL}/PowerBI` } as const;

// export const EncouterServices = {
//   PATIENTS: `${ENCOUNTER_API}/v1/patients`,
//   PRACTITIONERS: `${ENCOUNTER_API}/v1/practitioners`,
//   SITES: `${ENCOUNTER_API}/v1/sites`,
//   APPOINTMENTS: `${ENCOUNTER_API}/v1/appointments`,
//   APPOINTMENTS_FOR_SAME_PATIENT: `${ENCOUNTER_API}/v1/Appointments`,
//   TEST_REPORTS: `${ENCOUNTER_API}/v1/TestReports`,
//   USERS: `${ENCOUNTER_API}/v1/Users`,
//   CARE_PLAN: `${ENCOUNTER_API}/v1/care-plan`,
// } as const;

export const ResourceServices = { CONFIGURATIONS: `${RESOURCE_API}/v1/Configurations` } as const;
